<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <div class="py-3"></div>
      <v-card width="350px" class="mx-auto mt-4" cols="12" sm="4">
        <v-card-title class="card-title">
          <h3 class="display-2">パスワードの再設定</h3>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="メールアドレス"
              v-model="email"
            />
            <v-card-actions>
              <v-btn color="blue" @click="passwordRemind"
                >パスワード再設定</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
      <p v-if="alert !== ''" v-html="alert" class="error-text text-center"></p>
      <!--  -->
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    url: "",
    email: "",
    apiUrl: process.env.VUE_APP_API_BASE_URL,
    token: "",
    alert: "",
  }),
  created: function () {
    this.getToken();
  },
  methods: {
    getToken: function () {
      if (localStorage.getItem("loginData") === null) {
        return;
      } else {
        const obj = localStorage.getItem("loginData");
        const loginData = JSON.parse(obj);
        this.token = loginData.token;
      }
    },
    passwordRemind: function () {
      if (this.email === "") {
        alert("メールアドレスを入力してください");
        return;
      }
      this.axios
        .post(this.apiUrl + "forgot-password", {
          email: this.email,
        })
        .then((res) => {
          if (res.data.message === "passwords.sent") {
            this.alert =
              "パスワード再設定用のメールを送りました。<br>10分以上待っても届かない場合は迷惑フォルダもご確認ください。";
          }
        })
        .catch((error) => {
          if (error.response.request.status === 422) {
            alert("メールアドレスに誤りがある可能性があります");
            this.email = "";
            return;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.error-text {
  margin-top: 20px;
  color: darkblue;
  font-weight: bold;
}
</style>
