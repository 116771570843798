<template>
  <v-app class="bg-img">
    <div class="bg-img"></div>
    <v-container>
      <!-- 上部のボタン -->
      <br />
      <v-row justify="center">
        <v-col cols="12">
          <h2 class="text-center">ランキング開催中!!!</h2>
          <div class="text-center">
            <br />
            <v-btn v-if="loginFlug" class="rank-btn" to="ranking/question">
              ランキング問題に挑戦される方はこちら！
            </v-btn>
            <p v-else>
              ランキングに挑戦される方は<br />ログインをお願いします。
            </p>
          </div>
        </v-col>
        <v-col cols="12" class="text-center center">
          <v-tabs v-model="tab" color="blue" class="ma-4 center">
            <v-tab value="1">パソコンランキング</v-tab>
            <v-tab value="2">スマホランキング</v-tab>
          </v-tabs>
        </v-col>

        <v-col md="8" cols="12">
          <div v-if="loding">
            <loding />
          </div>
          <div v-else>
            <div v-if="get_status === 201">
              <p class="text-center">{{ massage }}</p>
            </div>
            <div v-else>
              <v-window v-model="tab">
                <v-window-item value="1">
                  <div class="text-center" v-if="loginFlug">
                    <p v-if="PCmyRank !== null" class="rankAlart ma-40">
                      {{ myName }}さんの順位は{{ PCmyRank }}位です！<br />
                      スコアは{{ PCmyScore }}です！
                    </p>
                    <p v-else class="rankAlart">
                      {{
                        myName
                      }}さんはまだパソコンのランキングに参加されていないみたいです
                    </p>
                  </div>
                  <div v-if="rankDataPc.length === 0 && !loginFlug">
                    <p class="text-center">{{ pcMassage }}</p>
                  </div>
                  <div v-else>
                    <ol class="gradient-list">
                      <li v-for="(rank, i) in rankDataPc" :key="i">
                        スコア: {{ rank.score }}
                        <hr />
                        ニックネーム： {{ rank.name }}
                      </li>
                    </ol>
                  </div>
                </v-window-item>
                <v-window-item value="2">
                  <div class="text-center" v-if="loginFlug">
                    <p v-if="SPmyRank !== null" class="rankAlart">
                      {{ myName }}さんの順位は{{ SPmyRank }}位です！<br />
                      スコアは{{ SPmyScore }}です！
                    </p>
                    <p v-else class="rankAlart">
                      {{
                        myName
                      }}さんはまだスマホのランキングに<br />参加されていないみたいです
                    </p>
                  </div>
                  <div v-if="rankDataSp.length === 0 && !loginFlug">
                    <p class="text-center">{{ spMassage }}</p>
                  </div>
                  <div v-else>
                    <ol class="gradient-list">
                      <li v-for="(rank, i) in rankDataSp" :key="i">
                        スコア: {{ rank.score }}
                        <hr />
                        ニックネーム： {{ rank.name }}
                      </li>
                    </ol>
                  </div>
                </v-window-item>
              </v-window>
              <!-- <main v-if="tabPC"></main>
              <main v-if="tabSP"></main> -->
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import loding from "../myLoding.vue";

export default {
  name: "blogAll",
  components: {
    loding,
  },
  data: () => ({
    loginFlug: false,
    token: "",
    title: "",
    tab: null,
    tabPC: true,
    tabSP: false,
    alert: "",
    rankData: [],
    rankDataPc: [],
    rankDataSp: [],
    PCmyRank: null, //pcのランク
    PCmyScore: 0,
    alertPc: "",
    SPmyRank: null, //spのランク
    SPmyScore: 0,
    alertSp: "",
    myName: "",
    typingID: null,
    myId: null,
    apiUrl: process.env.VUE_APP_API_BASE_URL,
    loding: true,
    massage: "",
    pcMassage: "",
    spMassage: "",
    get_status: null,
  }),
  async created() {
    // 画面幅取得
    this.width = window.innerWidth;
    this.getMyInfo();
    this.getRanking();
  },
  methods: {
    getRanking: function () {
      this.axios
        .get(this.apiUrl + "rank/index", {
          headers: {
            Authorization: "Bearer " + this.token,
            "Content-type": "application/json",
            Accept: "application/json,",
          },
        })
        .then((res) => {
          this.typingID = res.data.typing_id;
          // スマホ、PCともにデータがない場合
          if (res.data.status_code === 201) {
            this.get_status = 201;
            this.massage = "まだランキングのデータはありません";
            this.loding = false;
          }
          this.getRankQuestion(); //ランキング問題取得
          this.rankData = res.data.question;
          for (let i = 0; i < this.rankData.length; i++) {
            // PCの場合
            if (this.rankData[i].device_type === "pc") {
              this.rankDataPc.push({
                user_id: this.rankData[i].user_id,
                score: this.rankData[i].score,
                name: this.rankData[i].name,
                device: this.rankData[i].device_type,
              });
            }
            // スマホの場合
            if (this.rankData[i].device_type === "sp") {
              this.rankDataSp.push({
                user_id: this.rankData[i].user_id,
                score: this.rankData[i].score,
                name: this.rankData[i].name,
                device: this.rankData[i].device_type,
              });
            }
          }
          if (this.rankDataPc.length === 0) {
            this.pcMassage = "まだパソコンのランキングデータはありません";
          }
          if (this.rankDataSp.length === 0) {
            this.spMassage = "まだスマホのランキングデータはありません";
          }
          this.getMyRankPc();
          this.getMyRankSp();
          this.loding = false;
        });
    },
    getMyRankPc: function () {
      if (this.loginFlug === false) {
        return;
      }
      if (this.rankDataPc.length === 0) {
        this.alertPc = "まだパソコンのランキングデータはありません。";
        return;
      }
      for (let i = 0; i < this.rankDataPc.length; i++) {
        if (this.rankDataPc[i].user_id === this.myId) {
          this.PCmyRank = i + 1;
          this.PCmyScore = this.rankDataPc[i].score;
        } else {
          this.alertPc =
            this.myName +
            "さんはまだパソコンのランキングに参加されていないみたいです。";
        }
      }
    },
    getMyRankSp: function () {
      if (this.loginFlug === false) {
        return;
      }
      if (this.rankDataSp.length === 0) {
        this.alertPc = "まだスマホのランキングデータはありません。";
        return;
      }
      for (let i = 0; i < this.rankDataSp.length; i++) {
        if (this.rankDataSp[i].user_id === this.myId) {
          this.SPmyRank = i + 1;
          this.SPmyScore = this.rankDataSp[i].score;
        } else {
          this.alertSp =
            this.myName +
            "さんは、まだスマホのランキングに参加されていないみたいです。";
        }
      }
    },
    getMyInfo: function () {
      const obj = localStorage.getItem("loginData");
      if (obj === null) {
        this.alert = "ランキングに参加される方はログインしてください。";
        return;
      } else {
        this.loginFlug = true;
        const loginData = JSON.parse(obj);
        this.token = loginData.token;
        this.myId = loginData.userID;
        this.axios
          .get(this.apiUrl + "user", {
            headers: {
              Authorization: "Bearer " + this.token,
              "Content-type": "application / json",
              Accept: "application/json,",
            },
          })
          .then((res) => {
            this.myName = res.data.name;
          });
      }
    },
    tabPCBtn: function () {
      this.tabPC = true;
      this.tabSP = false;
      this.getMyRankPc();
    },
    tabSPBtn: function () {
      this.tabPC = false;
      this.tabSP = true;
      this.getMyRankSp();
    },
    getRankQuestion: function () {
      this.axios
        .get(this.apiUrl + "question/show/" + this.typingID)
        .then((res) => {
          this.title = res.data.title;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2? family= M+PLUS+Rounded+1c & display=swap");

.v-application {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}

.tab-height {
  height: 100% !important;
}

.center {
  justify-content: center;
}
.bg-color {
  background-color: #b2e5fb;
}
.card-title {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
  background-color: #54b3fc;
  margin-bottom: 20px;
  color: white;
  font-weight: bold;
}
.deco-none {
  text-decoration: none;
  color: #4d4d4d;
  margin-bottom: 20px;
}
.grey-color {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
  color: #4d4d4d;
}
.font-bold {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
  font-weight: bold;
}
.font-bold-link {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
  font-weight: bold;
  text-decoration: none;
  color: blue;
}

.rank-btn {
  background-color: #54b3fc;
  color: white;
  font-weight: bold;
}

.btn-nav {
  margin-top: 0px;
}

.alert {
  width: 60%;
  margin: 20px auto;
}
.bg-img {
  background-image: url("~@/assets/bg.png");
}

.bg-white {
  background-color: white;
}
.start-btn {
  margin: 0 auto;
}

.info {
  margin-top: 10px;
}
.select-btn {
  margin-top: 10px;
}

.rankAlart {
  font-weight: bold;
  color: #0080ff;
}
/* ランキング */
/*** FONTS ***/
@import url(https://fonts.googleapis.com/css?family=Montserrat:900|Raleway:400,400i,700,700i);

/*** VARIABLES ***/
/* Colors */
$black: #1d1f20;
$blue: #83e4e2;
$green: #a2ed56;
$yellow: #fddc32;
$white: #fafafa;

/*** EXTEND ***/
/* box-shadow */
%boxshadow {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05),
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}

/*** STYLE ***/
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: $white;
  color: $black;
  font-family: "Raleway", sans-serif;
}

main {
  display: block;
  margin: 0 auto;
  max-width: 40rem;
  padding: 1rem;
}

hr {
  margin: 10px;
}

ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;
  > li {
    text-align: center;
    font-weight: 600;
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    @extend %boxshadow;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
    &::before,
    &::after {
      background: linear-gradient(135deg, $blue 0%, $green 100%);
      border-radius: 1rem 1rem 0 1rem;
      content: "";
      height: 3rem;
      left: -1rem;
      overflow: hidden;
      position: absolute;
      top: -1rem;
      width: 3rem;
    }
    &::before {
      align-items: flex-end;
      @extend %boxshadow;
      content: counter(gradient-counter);
      color: $black;
      display: flex;
      font: 900 1.5em/1 "Montserrat";
      justify-content: flex-end;
      padding: 0.125em 0.25em;
      z-index: 1;
    }
    @for $i from 1 through 5 {
      &:nth-child(10n + #{$i}):before {
        background: linear-gradient(
          135deg,
          rgba($green, $i * 0.2) 0%,
          rgba($yellow, $i * 0.2) 100%
        );
      }
    }
    @for $i from 6 through 10 {
      &:nth-child(10n + #{$i}):before {
        background: linear-gradient(
          135deg,
          rgba($green, 1 - (($i - 5) * 0.2)) 0%,
          rgba($yellow, 1 - (($i - 5) * 0.2)) 100%
        );
      }
    }
    + li {
      margin-top: 2rem;
    }
  }
}
</style>
