<template>
  <v-app class="bg-img">
    <v-container>
      <!-- 上部のボタン -->
      <v-row>
        <v-col cols="12" v-if="myName !== ''">
          <p class="welcom">ようこそ、{{ myName }}さん !</p>
        </v-col>
        <v-col md="8" xs="12">
          <v-card :height="300" color="#B3E5FC" class="text-center">
            <div class="py-2"></div>
            <v-row>
              <v-col md="7" xs="12">
                <v-card-title class="grey-color font-bold"
                  >韓国語タイピング×単語学習</v-card-title
                >
                <v-card-text class="grey-color"
                  >韓国語無料タイピング、<br />コリアンタイピングへようこそ！<br />
                </v-card-text>
                <v-card-text class="grey-color"
                  >まず初めに腕試しで<br />自分のレベルをチェックしてみましょう。
                </v-card-text>
                <v-btn
                  to="game-select/TrilingualTomi"
                  class="text-center start-btn"
                  color="#ffff99"
                  height="70"
                  >今すぐチェック！</v-btn
                >
                <!-- color="#4FC3F7" -->
              </v-col>
              <v-col v-if="width > 640">
                <v-img :src="require('@/assets/zoo.png')"></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col md="4" cols="12" v-if="this.width < 640">
          <!-- <v-card
            v-if="width < 640"
            to="/ranking"
            :height="300"
            color="#B3E5FC"
            class="text-center"
          >
            <div class="py-2"></div>
            <v-row>
              <v-col md="12" xs="12">
                <v-card-title class="grey-color font-bold">
                  ランキング開催中!
                </v-card-title>
                <v-card-text
                  >いますぐランキングに参加して<br />自分の実力を確かめよう！</v-card-text
                >
              </v-col>
              <v-col>
                <v-img
                  width="220"
                  class="center-img"
                  :src="require('@/assets/22652934.png')"
                ></v-img>
              </v-col>
            </v-row>
          </v-card> -->
          <div class="py-3"></div>
          <!-- タイピングバラエティ -->
          <SelectVariety />
        </v-col>
        <v-col md="4" xs="12" v-if="this.width > 640" class="text-center">
          <SelectVariety />

          <!-- コリアンレター紹介 -->
          <!-- <KoreanLetter /> -->
          <!-- 広告 -->
          <!-- <a
            href="https://px.a8.net/svt/ejp?a8mat=3NPIDJ+9IC9MA+4GDM+63H8H"
            rel="nofollow"
          >
            <img
              class="scale-img"
              border="0"
              width="300"
              height="300"
              alt=""
              src="https://www25.a8.net/svt/bgt?aid=221219911575&wid=001&eno=01&mid=s00000020785001024000&mc=1"
          /></a>
          <img
            border="0"
            width="1"
            height="1"
            src="https://www19.a8.net/0.gif?a8mat=3NPIDJ+9IC9MA+4GDM+63H8H"
            alt=""
          /> -->
          <!-- <v-card
            v-if="width > 640"
            to="/ranking"
            :height="300"
            color="#B3E5FC"
            class="text-center"
          >
            <div class="py-2"></div>
            <v-row>
              <v-col md="12" xs="12">
                <v-card-title class="grey-color font-bold">
                  ランキング開催中!
                </v-card-title>
                <v-card-text
                  >いますぐランキングに参加して<br />自分の実力を確かめよう！</v-card-text
                >
              </v-col>
              <v-col>
                <v-img
                  width="220"
                  class="center-img"
                  :src="require('@/assets/22652934.png')"
                ></v-img>
              </v-col>
            </v-row>
          </v-card> -->
        </v-col>
      </v-row>
      <div class="py-3"></div>
      <v-row>
        <v-col md="6" cols="12">
          <v-card no-gutters>
            <v-card-title class="card-title" to="/info">お知らせ</v-card-title>
            <div v-if="this.loding == true">
              <loding />
            </div>
            <div v-else class="padding">
              <v-card-actions v-for="(info, i) in info" :key="i">
                <router-link :to="'/info/' + info.fields.url" class="deco-none"
                  >◆ {{ info.fields.title }}
                </router-link>
              </v-card-actions>
              <router-link :to="'info'">一覧へ</router-link>
            </div>
          </v-card>
        </v-col>
        <v-col md="6" cols="12">
          <v-card no-gutters>
            <v-card-title class="card-title">ブログ</v-card-title>
            <div v-if="this.loding == true">
              <loding />
            </div>
            <div v-else class="padding">
              <v-card-actions v-for="(blog, i) in blog" :key="i">
                <router-link :to="'/blog/' + blog.fields.url" class="deco-none"
                  >◆ {{ blog.fields.title }}</router-link
                >
              </v-card-actions>
              <router-link :to="'blog'">一覧へ</router-link>
            </div>
          </v-card>
        </v-col>
        <v-col md="4" cols="12" v-if="this.width > 640">
          <!-- タイピングバラエティ -->
          <!-- <SelectVariety /> -->
        </v-col>
        <v-col md="4" xs="12" v-if="this.width < 640" class="text-center">
          <!-- コリアンレター紹介 -->
          <!-- <KoreanLetter /> -->
          <!-- 広告 -->
          <a
            href="https://px.a8.net/svt/ejp?a8mat=3NPIDJ+9IC9MA+4GDM+63H8H"
            rel="nofollow"
          >
            <img
              class="scale-img"
              border="0"
              width="320"
              height="320"
              alt=""
              src="https://www25.a8.net/svt/bgt?aid=221219911575&wid=001&eno=01&mid=s00000020786401024000&mc=1"
          /></a>
          <img
            border="0"
            width="1"
            height="1"
            src="https://www19.a8.net/0.gif?a8mat=3NPIDJ+9IC9MA+4GDM+63H8H"
            alt=""
          />
        </v-col>
        <!-- <v-col md="4" xs="12">
          <v-btn to="/ranking">ランキング開催中!!!</v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12">
          <!-- アマゾン -->
          <Amazon />
        </v-col>
      </v-row>
      <br />
    </v-container>
  </v-app>
</template>

<script>
// import { defineComponent } from "vue";
import SelectVariety from "../views/SelectVariety.vue";
// import KoreanLetter from "../views/KoreanLetter.vue";
import Amazon from "../views/Pages/MyAmazon.vue";
import loding from "../views/myLoding.vue";
import { createClient } from "@/plugins/contentful";
// import MyBackground from "../views/MyBackground.vue";

export default {
  name: "HelloWorld",
  components: {
    SelectVariety,
    // KoreanLetter,
    Amazon,
    loding,
    // MyBackground,
  },
  data: () => ({
    width: 0,
    info: [],
    blog: [],
    loding: true,
    apiUrl: process.env.VUE_APP_API_BASE_URL,
    myName: "",
  }),
  async created() {
    // 画面幅取得
    this.width = window.innerWidth;
    await Promise.all([this.getBlog(), this.getInfo()]);
    this.getUser();
  },
  methods: {
    getInfo() {
      createClient()
        .getEntries({
          content_type: "info",
          order: "-sys.createdAt",
          limit: 3,
        })
        .then((res) => {
          this.info = res.items;
          this.loding = false;
        });
    },
    getBlog() {
      createClient()
        .getEntries({
          content_type: "blog",
          order: "-sys.createdAt",
          limit: 3,
        })
        .then((res) => {
          this.blog = res.items;
          this.loding = false;
        });
    },
    getUser() {
      if (localStorage.getItem("loginData") === null) {
        return;
      }
      const obj = localStorage.getItem("loginData");
      const loginData = JSON.parse(obj);
      const token = loginData.token;
      this.axios
        .get(this.apiUrl + "user", {
          headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application / json",
            Accept: "application/json,",
            data: {},
          },
        })
        .then((res) => {
          this.myName = res.data.name;
        });
    },
  },
};
</script>

<style>
.v-container {
  max-width: 997px;
}
.card-title {
  background-color: #54b3fc;
  margin-bottom: 20px;
  color: white;
  font-weight: bold;
}
.center-img {
  margin: 0 auto;
}
.welcom {
  font-weight: bold;
  color: #4d4d4d;
  font-size: 20px;
}
.deco-none {
  text-decoration: none;
  color: #4d4d4d;
  margin-bottom: 20px;
}
.grey-color {
  color: #4d4d4d;
}
.font-bold {
  font-weight: bold;
}

.bg-img {
  background-image: url("~@/assets/bg.png");
}

.bg-white {
  background-color: white;
}
.start-btn {
  margin: 0 auto;
}

.info {
  margin-top: 10px;
}
.select-btn {
  margin-top: 10px;
}
.padding {
  padding-left: 20px;
  padding-bottom: 20px;
}
.scale-img {
  width: 300px; /*画像の幅*/
  height: 300px; /*画像の高さ*/
  transition-duration: 0.5s; /*変化の時間*/
}
.scale-img:hover {
  transform: scale(1.1, 1.1); /*画像の拡大*/
  cursor: pointer; /*カーソルをポインターにする*/
}
/*  */
</style>
