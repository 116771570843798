const typingKey = function (key) {
  if (key == 16) {
    const text = "shift";
    return text;
  }
  // } else if (key == "ㄴ") {
  //   let text = "";
  //   text = "ᄂ";
  //   return text;
  // } else if (key == "ㄷ") {
  //   let text = "";
  //   text = "ᄃ";
  //   return text;
  // } else if (key == "ㄹ") {
  //   let text = "";
  //   text = "ᄅ";
  //   return text;
  // } else if (key == "ㅁ") {
  //   let text = "";
  //   text = "ᄆ";
  //   return text;
  // } else if (key == "ㅂ") {
  //   let text = "";
  //   text = "ᄇ";
  //   return text;
  // } else if (key == "ㅅ") {
  //   let text = "";
  //   text = "ᄉ";
  //   return text;
  // } else if (key == "ㅇ") {
  //   let text = "";
  //   text = "ᄋ";
  //   return text;
  // } else if (key == "ㅈ") {
  //   let text = "";
  //   text = "ᄌ";
  //   return text;
  // } else if (key == "ㅊ") {
  //   let text = "";
  //   text = "ᄎ";
  //   return text;
  // } else if (key == "ㅋ") {
  //   let text = "";
  //   text = "ᄏ";
  //   return text;
  // } else if (key == "ㅌ") {
  //   let text = "";
  //   text = "ᄐ";
  //   return text;
  // } else if (key == "ㅍ") {
  //   let text = "";
  //   text = "ᄑ";
  //   return text;
  // } else if (key == "ㅎ") {
  //   let text = "";
  //   text = "ᄒ";
  //   return text;
  //   //   母音
  // } else if (key == "ㅏ") {
  //   let text = "";
  //   text = "ᅡ";
  //   return text;
  // } else if (key == "ㅑ") {
  //   let text = "";
  //   text = "ᅣ";
  //   return text;
  // } else if (key == "ㅓ") {
  //   let text = "";
  //   text = "ᅥ";
  //   return text;
  // } else if (key == "ㅕ") {
  //   let text = "";
  //   text = "ᅧ";
  //   return text;
  // } else if (key == "ㅗ") {
  //   let text = "";
  //   text = "ᅩ";
  //   return text;
  // } else if (key == "ㅛ") {
  //   let text = "";
  //   text = "ᅭ";
  //   return text;
  // } else if (key == "ㅜ") {
  //   let text = "";
  //   text = "ᅮ";
  //   return text;
  // } else if (key == "ㅠ") {
  //   let text = "";
  //   text = "ᅲ";
  //   return text;
  // } else if (key == "ㅡ") {
  //   let text = "";
  //   text = "ᅳ";
  //   return text;
  // } else if (key == "ㅐ") {
  //   let text = "";
  //   text = "ᅢ";
  //   return text;
  // } else if (key == "ㅔ") {
  //   let text = "";
  //   text = "ᅦ";
  //   return text;
  // } else if (key == "ㅣ") {
  //   let text = "";
  //   text = "ᅵ";
  //   return text;
  //   // パッチム
  // } else if (key == "ㅆ") {
  //   let text = "";
  //   text = "{shift} ᄊ";
  //   return text;
  // } else if (key == "ㅃ") {
  //   let text = "";
  //   text = "{shift} ᄈ";
  //   return text;
  // } else if (key == "ㄲ") {
  //   let text = "";
  //   text = "{shift} ᄁ";
  //   return text;
  // } else if (key == "ㅉ") {
  //   let text = "";
  //   text = "{shift} ᄍ";
  //   return text;
  // } else if (key == "ㄸ") {
  //   let text = "";
  //   text = "{shift} ᄄ";
  //   return text;
  // } else if (key == "ㅒ") {
  //   let text = "";
  //   text = "{shift} ᅤ";
  //   return text;
  // } else if (key == "ㅖ") {
  //   let text = "";
  //   text = "{shift} ᅨ";
  //   return text;
  // } else if (key == " ") {
  //   let text = "";
  //   text = "{space}";
  //   return text;
  // } else {
  //   console.log("ハングルモードに変更しましょう");
  // }
};

export default { typingKey };
