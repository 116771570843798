<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <!-- 
        会員情報変更
       -->
      <v-card width="100%" class="mx-auto mt-4" cols="12" sm="4">
        <v-card-title class="card-title">
          <h3 class="display-2">会員情報編集</h3>
        </v-card-title>
        <v-card-text>
          <v-form v-if="!changeFlug">
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="ニックネーム"
              v-model="nickname"
              readonly
            />
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="メールアドレス"
              v-model="email"
              type="email"
              readonly
            />
            <v-card-actions>
              <v-btn color="blue" @click="changeFlug = !changeFlug">編集</v-btn>
              <v-btn color="blue" to="/password-remind"
                >パスワード変更はこちら</v-btn
              >
            </v-card-actions>
          </v-form>
          <v-form v-if="changeFlug">
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="ニックネーム"
              v-model="nickname"
            />
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="メールアドレス"
              type="email"
              v-model="email"
            />
            <v-card-actions>
              <v-btn color="blue" @click="changeFlug = !changeFlug"
                >変更をやめる</v-btn
              ><br />
              <v-btn color="blue" @click="changeMyInfo">変更を確定する</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
      <div class="py-3"></div>
    </v-container>
  </v-app>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    nickname: "",
    email: "",
    changeFlug: false,
    token: "",
    apiUrl: process.env.VUE_APP_API_BASE_URL,
  }),
  created: function () {
    // ログインしていなかったらログイン画面に遷移
    if (localStorage.getItem("loginData") === null) {
      alert("ログインしてください");
      this.$router.push({ path: "/login" });
    }
    this.getMyInfo();
  },
  methods: {
    getMyInfo() {
      const obj = localStorage.getItem("loginData");
      const loginData = JSON.parse(obj);
      this.token = loginData.token;
      axios
        .get(this.apiUrl + "user", {
          headers: {
            Authorization: "Bearer " + this.token,
            "Content-type": "application/json",
            Accept: "application/json,",
            data: {},
          },
        })
        .then((res) => {
          this.nickname = res.data.name;
          this.email = res.data.email;
        })
        .catch((e) => {
          if (e.response.data.message === "Unauthenticated.") {
            alert("問題が発生しました。再度ログインをお願いします");
            localStorage.clear();
            this.$router.push({ path: "/login" });
          }
        });
    },
    changeMyInfo() {
      if (this.nickname === "" || this.email === "") {
        alert("情報を入力してください。");
        return;
      }
      axios
        .post(
          this.apiUrl + "change-nickname",
          {
            email: this.email,
            nickname: this.nickname,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
              "Content-type": "application/json",
              Accept: "application/json,",
            },
          }
        )
        .then((res) => {
          if (res.data.status_code === 200) {
            alert("変更が完了しました！");
            this.$router.push({ path: "/login" });
          }
        })
        .catch((e) => {
          if (e.response.data.message === "Unauthenticated.") {
            alert("問題が発生しました。再度ログインをお願いします");
            localStorage.clear();
            this.$router.push({ path: "/login" });
          }
        });
    },
    change() {
      this.changeFlug = true;
    },
  },
};
</script>
<style lang="scss" scoped></style>
