<template>
  <v-app class="bg-img">
    <div class="py-3 bg-img"></div>
    <!-- 問題 -->
    <v-container>
      <v-row class="text-center">
        <v-col>
          <div v-if="show && !completed">
            <h2 class="grey-color">
              {{ textIndex + 1 }} / {{ this.questions.length }}
            </h2>
            <p class="font-size-big">
              <span class="text-blue" v-html="this.ClearText"></span
              ><span class="grey-color">{{ currentText }}</span>
            </p>
            <p class="grey-color">
              {{ this.questions[this.randomMath[this.textIndex]].translation }}
            </p>
            <br />
            <v-row v-if="url == 'kpop'">
              <v-col
                class="grey-color"
                v-if="
                  this.questions[this.randomMath[this.textIndex]].music !==
                  undefined
                "
                >曲名:{{
                  this.questions[this.randomMath[this.textIndex]].music
                }}</v-col
              >
              <v-col
                class="grey-color"
                v-if="
                  this.questions[this.randomMath[this.textIndex]].lyrics !==
                  undefined
                "
                >作詞:{{
                  this.questions[this.randomMath[this.textIndex]].lyrics
                }}</v-col
              >
            </v-row>
            <!-- 入力窓 -->
            <v-container v-if="autofoucus == true">
              <v-textarea
                v-if="this.DisplayWidth > 640"
                :value="input"
                v-on:keydown="onKeyDown"
                v-on:keyup="onKeyUp"
                filled
                autofocus
                class="input bg-color"
                height="600"
                @input="onInputChange"
              ></v-textarea>
              <input
                v-else
                :value="input"
                filled
                autofocus
                class="bg-color-input"
                height="200"
                ref="spInput"
                @input="onInputChange"
              />
              <!-- キーボード -->
              <SimpleKeyboard
                v-if="this.DisplayWidth > 640 && keySuport"
                @onChange="onChange"
                @onKeyPress="onKeyPress"
                :input="input"
                :hangul="hangulChange"
                :typing="typingText"
                :keydown="keydown"
                :keyup="keyup"
                :shiftToggle="shiftToggle"
              />
            </v-container>
          </div>
        </v-col>
      </v-row>

      <div v-if="show && completed">
        <v-container>
          <v-row class="text-center">
            <v-col cols="12">
              <v-card>
                <v-card-title class="card-title">結果</v-card-title>
                <p>お疲れ様でした！</p>
                <br />
                <!-- PCの結果 -->
                <div v-if="DisplayWidth > 640 && scoreFlug === true">
                  <!-- 一言解説 -->
                  <p v-if="level == 'D-' || level == 'D' || level == 'D+'">
                    あなたのタイピングはまだイマイチかも...<br />
                    ミスを減らせばもっといい結果を出せるはず！！！
                  </p>
                  <p v-if="level == 'C-' || level == 'C' || level == 'C+'">
                    あなたのタイピングは日本人の中では早い方です！<br />
                    もう少しで韓国人並みになれるかも！！！
                  </p>
                  <p v-if="level == 'B-' || level == 'B' || level == 'B+'">
                    あなたのタイピングは韓国人並み！<br />
                    韓国人とのやり取りでも困らないでしょう！<br />
                    でももっと高みを目指せるはず！
                  </p>
                  <p v-if="level == 'A-' || level == 'A' || level == 'A+'">
                    あなたのタイピングは韓国人でも早い方！<br />
                    もう韓国人にも驚かれる速度です！！！
                  </p>
                  <br />
                  <p>
                    スコア: &nbsp;
                    <span class="text-blue-data">{{ score }}</span>
                  </p>
                  <br />
                  <p>
                    レベル:&nbsp;
                    <span class="text-blue-data"> {{ level }}</span>
                  </p>
                  <br />
                  <p>
                    入力時間:&nbsp;
                    <span class="text-blue-data"> {{ getTimeStr() }}</span>
                  </p>
                  <br />
                  <p>
                    タイピング数:&nbsp;
                    <span class="text-blue-data"> {{ typingNumAll }}</span>
                  </p>
                  <br />
                  <p>
                    ミス入力数:&nbsp;
                    <span class="text-blue-data"> {{ missIndex }}</span>
                  </p>
                  <br />
                  <p>
                    WPM:&nbsp;
                    <span class="text-blue-data">{{ wpm }}</span>
                  </p>
                  <br />
                  <p>
                    正確率:&nbsp;
                    <span class="text-blue-data"> {{ Accuracy }}%</span>
                  </p>
                  <br />
                  <div v-if="DisplayWidth > 640 && missTyping.length > 0">
                    <p>
                      <span>苦手キー: </span
                      ><span
                        v-for="(miss, i) in missTyping"
                        :key="i"
                        class="text-blue-data"
                        >{{ miss.key }}
                        <span v-if="i !== missTyping.length - 1">、</span>
                      </span>
                    </p>
                  </div>
                </div>
                <!-- スマホの結果 -->
                <div v-if="DisplayWidth < 640 && scoreFlug === true">
                  <!-- 一言解説 -->
                  <p v-if="level == 'D-' || level == 'D' || level == 'D+'">
                    あなたのタイピングはまだイマイチかも...<br />
                    ミスを減らせばもっといい結果を出せるはず！！！
                  </p>
                  <p v-if="level == 'C-' || level == 'C' || level == 'C+'">
                    あなたのタイピングは日本人の中では早い方です！<br />
                    もう少しで韓国人並みになれるかも！！！
                  </p>
                  <p v-if="level == 'B-' || level == 'B' || level == 'B+'">
                    あなたのタイピングは韓国人並み！<br />
                    韓国人とのやり取りでも困らないでしょう！<br />
                    でももっと高みを目指せるはず！
                  </p>
                  <p v-if="level == 'A-' || level == 'A' || level == 'A+'">
                    あなたのタイピングは韓国人でも早い方！<br />
                    もう韓国人にも驚かれる速度です！！！
                  </p>
                  <br />
                  <p>
                    スコア: &nbsp;
                    <span class="text-blue-data">{{ score }}</span>
                  </p>
                  <br />
                  <p>
                    レベル:&nbsp;
                    <span class="text-blue-data"> {{ level }}</span>
                  </p>
                  <br />
                  <p>
                    入力時間:&nbsp;
                    <span class="text-blue-data"> {{ getTimeStr() }}</span>
                  </p>
                </div>

                <!-- <p>得点: {{ this.score }} / {{ this.questions.length }}</p> -->
                <!-- <router-link to="/">ランキングをみる</router-link> -->
                <br />
                <v-card-text v-if="siteIntroduction !== null">
                  今回のタイピング問題は<br />
                  <a
                    :href="siteIntroduction.url"
                    class="deco-none-blue"
                    target="_brank"
                    rel="noopener noreferrer"
                  >
                    {{ siteIntroduction.site }} </a
                  ><br />
                  で取り扱っている<br />
                  <a
                    :href="siteIntroduction.question_url"
                    class="deco-none-blue"
                    target="_brank"
                    rel="noopener noreferrer"
                  >
                    {{ siteIntroduction.title }}</a
                  ><br />を元に 準備しております。<br /><br />
                  より詳しく勉強したい方はぜひご利用ください！
                </v-card-text>
                <br />
                <v-row justify="center">
                  <v-col md="4" xs="12">
                    <v-btn color="#4FC3F7" @click="reload">
                      もう一度挑戦する
                    </v-btn>
                  </v-col>
                  <v-col md="4" xs="12">
                    <v-btn color="#4FC3F7" to="/"> Home画面に戻る </v-btn>
                  </v-col>
                  <v-col v-if="this.userID !== ''">
                    <v-btn color="#4FC3F7" @click="scoreRegister">
                      ランキングに登録する
                    </v-btn>
                  </v-col>
                  <v-col md="4" xs="12">
                    <!-- ツイッター トリリンガルのトミ -->
                    <!-- スマホの場合 -->
                    <ShareNetwork
                      v-if="url == 'tomi' && DisplayWidth < 640"
                      network="twitter"
                      url="https://korean-typing.com/"
                      :title="
                        '【スマホで挑戦】コリアンタイピング：トリリンガルのトミ「' +
                        this.title +
                        '」で' +
                        this.level +
                        'ランクを獲得しました!'
                      "
                      hashtags="コリアンタイピング,韓国語勉強,韓国語学習,韓国語"
                      twitter-user="taichi_k_web"
                    >
                      <v-btn color="#ffff99" to="/" class="deco-none">
                        twitterでシェアする!
                      </v-btn>
                    </ShareNetwork>
                    <!-- PCの場合 -->
                    <ShareNetwork
                      v-if="url == 'tomi' && DisplayWidth > 640"
                      network="twitter"
                      url="https://korean-typing.com/"
                      :title="
                        '【PCで挑戦】コリアンタイピング：トリリンガルのトミ「' +
                        this.title +
                        '」で' +
                        this.level +
                        'ランクを獲得しました!'
                      "
                      hashtags="コリアンタイピング,韓国語勉強,韓国語学習,韓国語"
                      twitter-user="taichi_k_web"
                    >
                      <v-btn color="#ffff99" to="/" class="deco-none">
                        twitterでシェアする!
                      </v-btn>
                    </ShareNetwork>
                    <!-- ツイッター -->
                    <!-- スマホ -->
                    <ShareNetwork
                      v-if="url !== 'tomi' && DisplayWidth < 640"
                      network="twitter"
                      url="https://korean-typing.com/"
                      :title="
                        '【スマホで挑戦】コリアンタイピング「' +
                        this.title +
                        '」で' +
                        this.level +
                        'ランクを獲得しました!'
                      "
                      hashtags="コリアンタイピング,韓国語勉強,韓国語学習,韓国語"
                      twitter-user="taichi_k_web"
                    >
                      <v-btn color="#ffff99" to="/">
                        twitterでシェアする!
                      </v-btn>
                    </ShareNetwork>
                    <!-- PC -->
                    <ShareNetwork
                      v-if="url !== 'tomi' && DisplayWidth > 640"
                      network="twitter"
                      url="https://korean-typing.com/"
                      :title="
                        '【PCで挑戦】コリアンタイピング「' +
                        this.title +
                        '」で' +
                        this.level +
                        'ランクを獲得しました!'
                      "
                      hashtags="コリアンタイピング,韓国語勉強,韓国語学習,韓国語"
                      twitter-user="taichi_k_web"
                    >
                      <v-btn color="#ffff99" to="/">
                        twitterでシェアする!
                      </v-btn>
                    </ShareNetwork>
                  </v-col>
                </v-row>
                <div class="py-3"></div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="py-5"></div>

      <!-- ダイアログ -->
      <v-row justify="center">
        <v-dialog v-model="dialog" width="600px" persistent>
          <v-card>
            <v-card-title v-if="this.url == 'check'" class="card-title">
              腕試しレベルチェック
            </v-card-title>
            <v-card-text class="grey-color">
              キーボードは韓国語入力モードに変更しましたか？<br />
              準備できたらスタートしましょう！<br /><br />
              ※スマホだと正常に動作しない場合があります。<br />
              ご了承ください。
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-switch
                v-model="keySuport"
                label="キーボードサポート"
                color="blue"
                inset
                v-if="DisplayWidth > 640"
              ></v-switch>
              <div v-if="!show">
                <loding />
              </div>
              <div v-else>
                <v-btn @click="gameStart" color="blue"> スタート！ </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- <props :objects="objects"> </props> -->
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import SimpleKeyboard from "../SimpleKeyboard";
import Hangul from "@/plugins/korean";
import windowsHangul from "@/plugins/hangulChange";
import "@mdi/font/css/materialdesignicons.css";
import loding from "../myLoding.vue";

export default defineComponent({
  name: "App",
  emits: ["accepted"],
  components: {
    SimpleKeyboard,
    loding,
  },
  computed: {
    // 現在のクイズ内容を自動的に取得できる疑似変数
    currentText: function () {
      this.koreanChange(); // 次のキーボードを光らせる
      return this.questions[this.randomMath[this.textIndex]].origin;
    },
    // クイズの解答がすべて完了しているかどうかが分かる疑似変数
    completed() {
      return this.questions.length === this.clearCount;
    },
  },
  data: () => ({
    apiUrl: process.env.VUE_APP_API_BASE_URL,
    userID: null,
    typingID: null,
    getTypingIdFlug: false,
    register: false,
    device_type: "",
    rankFlug: false,
    scoreFlug: false,
    os: "", //OS判定
    // コンテントフル
    seoTitle: "",
    seoDescription: "",
    texts: [], // 受け取り窓口
    title: "", // お題のタイトル
    siteIntroduction: [], // ブログ紹介文
    questions: [], // 問題格納場所
    // タイピングアプリ
    textIndex: 0, //現在のクイズ番号を保持
    input: "", //入力したキーボード
    buttons: [], //画面上のキーボード
    typingText: "", //入力したキーボード
    url: "", //パラメータ取得
    dialog: true, //ダイアログの表示有無
    autofoucus: false, //スタートしたらキーボード表示
    NowText: 0, // 今入力したい文字
    ClearText: "",
    keySuport: true, // キーボードサポートの有無フラグ
    nowTyping: 0, // 今入力している文字の番号（場所）
    hangulChange: "", // キーボードを光らせるための次の単語ワード
    DisplayWidth: 0, // 画面幅
    keydown: "",
    keyup: "",
    typingNum: 0, //文字カウント(キーボードサポートのために使用)
    shiftToggle: false, //Shiftを押したかどうか(キーボードサポートのために使用)
    hangulArray: [], //最初の１文字目を韓国語で分けたものの配列
    randomMath: [], // 重複なしのランダムな数字（問題ランダム表示に使用
    show: false,
    showComp: false,
    clearCount: 0,
    // スコア計算式
    missIndex: 0, //失敗した数
    missTyping: [],
    missTyping2: [], //jsonの登録用
    score: 0,
    level: "",
    originLength: 0, //問題の文字数(空欄込み)
    inputTypeLength: 0, //入力文字数
    typingNumAll: 0, //タイピングした文字のカウント
    wpm: 0, //ミスも含めた入力文字数の一分に換算したときの入力できるキー数
    Accuracy: 0,
    // ストップウオッチ
    status: "clear",
    time: 0,
    startTime: null,
    stopTime: 0,
    minutes: 0,
    seconds: 0,
    typingTime: 0, // 秒数
  }),
  created() {
    // OS判定
    this.getOS();
    // 画面幅取得
    this.DisplayWidth = window.innerWidth;
    // URLからパラメータ取得
    const url = this.$route.path.split("/");
    this.url = url[2];
    this.getDviceType(); //デバイス情報取得
    this.getUserID(); // ユーザID取得
    this.getTypingID(); // タイピングID取得
    // this.getQuestion(); //問題取得
    this.moveToTop(); // 画面トップに移動
    this.setup(); //SEO対策
    this.show = true;
  },
  methods: {
    // ヘッダー情報
    setup() {
      const siteData = reactive({
        title: this.seoTitle,
        description: this.seoDescription,
      });
      useHead({
        // Can be static or computed
        title: computed(() => siteData.title),
        meta: [
          {
            name: "description",
            content: computed(() => siteData.description),
          },
        ],
      });
    },
    onChange(input) {
      this.input = input;
    },
    onKeyPress(button) {
      this.buttons.push(button);
    },
    onInputChange(input) {
      this.input = input.target.value;
      this.typingText = input.data;
      let originText = this.questions[this.randomMath[this.textIndex]].origin; // オリジナル文章
      let firstText = originText.charAt(0); // オリジナル文章の最初の1文字目(1文字目は正解するごとに削除されていく)
      let firstHangul = Hangul.disassemble(firstText); // 最初の１文字目を韓国語で分けたものの配列
      let nowTyping = 0;
      if (this.typingText == firstHangul[nowTyping]) {
        nowTyping++;
        if (this.typingText == firstText) {
          nowTyping = 0;
        }
      }
      // スマホで入力欄が空にならないバグ修正
      this.inputClear();
      this.clear();
    },
    // 重複なしのランダムな数字(問題ランダム表示に使用)
    randomNum() {
      let randoms = [];
      let min = 0;
      let max = this.questions.length - 1;
      /** 重複チェックしながら乱数作成 */
      for (let i = min; i <= max; i++) {
        var tmp = intRandom(min, max);
        if (!randoms.includes(tmp)) {
          randoms.push(tmp);
        } else {
          i = i - 1;
        }
      }
      /** min以上max以下の整数値の乱数を返す */
      function intRandom(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      this.randomMath = randoms;
    },
    onKeyDown(e) {
      let key = "";
      if (this.os === "macOS") {
        key = e.key;
      }
      if (this.os === "windows") {
        let windowsKey = windowsHangul(e.code, this.shiftToggle);
        key = windowsKey;
      }
      this.typingNumAll++;
      if (this.hangulChange == key) {
        this.typingNum++;
        this.koreanChange();
      } else if (key == "Shift" && this.hangulChange == "{shiftleft}") {
        this.typingNum++;
        this.koreanChange();
      } else if (
        (this.hangulChange !== key && key == "Backspace") ||
        key == "Enter" ||
        this.hangulChange == "{shiftleft}" ||
        key == "Space" ||
        !key.trim()
      ) {
        this.koreanChange();
      } else {
        this.missIndex++;
        this.missTyping.push(this.hangulChange);
      }
      // ただshiftおしたとき
      if (key == "Shift") {
        this.shiftToggle = true;
        this.keydown = key;
      } else {
        this.keydown = key;
      }
    },
    onKeyUp(e) {
      let keycode = "";
      if (this.os === "macOS") {
        keycode = e.key;
      }
      if (this.os === "windows") {
        let windowsKey = windowsHangul(e.code);
        keycode = windowsKey;
      }

      if (keycode == "Shift") {
        this.shiftToggle = false;
        this.keyup = "Shift";
      } else {
        this.keyup = keycode;
      }
    },
    clear() {
      let originText = this.questions[this.randomMath[this.textIndex]].origin; // オリジナル文章
      let firstText = originText.charAt(0); // オリジナル文章の最初の1文字目
      let SecondText = "";
      // スマホでタイピングが反応しない時のバグ修正
      // 原因：スマホで入力すると、this.typingTextが2文字になることがあり、firstTextと一致しなくなっていた
      if (
        this.DisplayWidth < 640 &&
        this.typingText !== null &&
        this.typingText.length === 2
      ) {
        SecondText = this.typingText.charAt(1);
        // 次が空欄の場合もthis.typingTextが2文字になるため、修正
        if (SecondText == firstText && SecondText.trim()) {
          this.NowText++; // 今の文字を+1
          this.questions[this.randomMath[this.textIndex]].origin =
            originText.slice(1); // オリジナル文章を１文字消す
          this.ClearText += SecondText; // 正解した文章を作成（青色の文字を追加するため）
          this.koreanChange(); // 次のキーボードを光らせ
          this.inputClear(); // スマホで入力欄が空にならないバグ
          this.typingNum = 0;
        }
        // 入力した文字と問題のテキストが一致している場合
      } else if (this.typingText == firstText) {
        this.NowText++; // 今の文字を+1
        this.questions[this.randomMath[this.textIndex]].origin =
          originText.slice(1); // オリジナル文章を１文字消す
        this.ClearText += this.typingText; // 正解した文章を作成（青色の文字を追加するため）
        this.koreanChange(); // 次のキーボードを光らせる
        this.inputClear(); // スマホで入力欄が空にならないバグ
        this.typingNum = 0;
        // もし、間違っていた、かつ、タイプ的にはあっていた場合
      } else if (
        this.typingText !== firstText &&
        this.typingNum == this.hangulArray.length
      ) {
        this.typingNum = 0;
        this.koreanChange();
      }
      //次の文字が空なら
      if (!originText.charAt(0).trim()) {
        this.questions[this.randomMath[this.textIndex]].origin =
          originText.slice(1); // オリジナル文章を１文字消す
        this.ClearText += "<span>&nbsp;</span>"; // 青文字の方も１文字開ける
        this.koreanChange(); // 次のキーボードを光らせる
        this.typingNum = 0;
      }
      // もし問題文が空なら(全問正解したら) または 入力された文字と問題文が一致していたら または 空欄を消した問題分と一致していたら
      if (
        this.questions[this.randomMath[this.textIndex]].origin.length <= 0 ||
        // this.input.indexOf(
        //   this.questions[this.randomMath[this.textIndex]].origin
        // ) > -1 ||
        this.input ==
          this.questions[this.randomMath[this.textIndex]].origin.replace(
            /\s+/g,
            ""
          )
      ) {
        // 中身を空にする
        this.buttons = [];
        this.typingText = "";
        this.NowText = 0;
        this.ClearText = "";
        this.clearCount++;
        this.textIndex++;
        this.input = "";
        // if (this.DisplayWidth < 640) {
        //   this.input = "7";
        //   const spInput = this.$refs["spInput"];
        //   spInput.blur(); //一度フォーカスを外す
        //   const focus = function () {
        //     spInput.focus();
        //   };
        //   setTimeout(focus, 200); //またフォーカスする
        // }
        // 問題全て完了した場合
        if (this.completed) {
          this.stopWatchStop();
          this.missKey();
          this.CountTypingTime();
          // スコア計算
          this.countWPM();
          this.accuracyCount();
          this.scoreCheck();
          this.checkLevel();
          this.showComp = true;
        }
      }
    },
    // ゲームスタート
    gameStart() {
      this.dialog = false;
      this.autofoucus = true;
      if (this.keySuport == true) {
        this.koreanChange();
      }
      // タイムアタックなら
      if (this.url == "time-atack") {
        this.timeStart();
        // 1秒ごとに処理が走る
        this.interval = setInterval(this.timeStart, 1000);
      } else {
        this.stopWatchStart();
      }
    },
    // スクロールでトップに移動
    moveToTop() {
      document.getElementById("page-top").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    // ストップウォッチ
    // スタート
    stopWatchStart() {
      this.status = "start";
      if (this.startTime === null) {
        this.startTime = Date.now();
      }
      const checkTime = () => {
        this.time = Date.now() - this.startTime + this.stopTime;
      };
      this.timer = setInterval(checkTime, 10);
    },
    // ストップ
    stopWatchStop() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.status = "stop";
      this.startTime = null;
      this.stopTime = this.time;
    },
    // リセット
    reset() {
      this.stop();
      this.status = "clear";
      this.time = 0;
      this.startTime = null;
      this.stopTime = 0;
    },
    getTimeStr() {
      // this.time is milliseconds
      // 1秒 = 1000ミリ秒
      // 1分 = 60 * 1000ミリ秒
      // 1時間 = 60 * 60 * 1000ミリ秒
      // let milliseconds = this.time % 1000;
      let seconds = Math.floor((this.time / 1000) % 60);
      let minutes = Math.floor((this.time / (60 * 1000)) % 60);

      this.seconds = seconds;
      this.minutes = minutes;

      return `${minutes}分${seconds}秒`;
    },
    // リロード
    reload() {
      // if (this.completed) {
      //   this.storeRankingScore();
      // }
      location.reload();
    },
    // ①入力時間
    CountTypingTime() {
      this.typingTime = Math.floor(this.time / 1000);
    },
    // ②入力文字数(ミスも含めた半角英数字) this.typingNumAll
    // ③ミス入力数(半角英数字のミス入力したキー数) this.missIndex
    // ④WPM(ミスも含めた入力文字数の一分に換算したときの入力できるキー数。多分これも切り捨て)
    countWPM() {
      let oneMinutesTyping = this.typingTime / 60;
      let wpm = this.typingNumAll / oneMinutesTyping;
      this.wpm = Math.floor(wpm);
    },
    // 正確率(正確に入力したキー数（①－②）÷入力文字数（①）×100)
    // 正答率(正しいタイプ数 ÷ 問題数 × 100)
    accuracyCount() {
      // 正確に入力したキー数
      let AccuracyTyping = this.typingNumAll - this.missIndex;
      let Accuracy = (AccuracyTyping / this.typingNumAll) * 100;
      this.Accuracy = Math.floor(Accuracy);
    },
    // 最終的なスコア(WPM×（正確率÷100)の3乗)
    scoreCheck() {
      // PCの場合
      if (this.DisplayWidth > 640) {
        let Accuracy = this.Accuracy / 100;
        let Accuracy3 = Accuracy * Accuracy * Accuracy;
        let scoreDecimal = this.wpm * Accuracy3;
        let scoreInteger = Math.floor(scoreDecimal);
        this.score = scoreInteger;
      }
      // スマホの場合
      if (this.DisplayWidth < 640) {
        // 時間 this.typingTime
        // 文字数 this.originLength
        // 1分間にどれだけタイピングできるか
        // 適当な数(スマホだと難しくなりすぎるから)
        const spNumber = 30;
        // 1分間のタイプ数 + 適当な数
        const minutesType = Math.floor(
          (this.originLength / this.typingTime) * 100
        );
        this.score = minutesType + spNumber;
      }
      this.scoreFlug = true;
    },
    checkLevel() {
      if (this.score < 21) {
        this.level = "E-";
      } else if (this.score < 38) {
        this.level = "E";
      } else if (this.score < 55) {
        this.level = "E+";
      } else if (this.score < 72) {
        this.level = "D-";
      } else if (this.score < 89) {
        this.level = "D";
      } else if (this.score < 106) {
        this.level = "D+";
      } else if (this.score < 123) {
        this.level = "C-";
      } else if (this.score < 140) {
        this.level = "C";
      } else if (this.score < 157) {
        this.level = "C+";
      } else if (this.score < 174) {
        this.level = "B-";
      } else if (this.score < 191) {
        this.level = "B";
      } else if (this.score < 208) {
        this.level = "B+";
      } else if (this.score < 225) {
        this.level = "A-";
      } else if (this.score < 242) {
        this.level = "A";
      } else if (this.score < 259) {
        this.level = "A+";
      } else if (this.score < 276) {
        this.level = "S";
      } else if (this.score < 299) {
        this.level = "Good!!!";
      } else if (300 <= this.score) {
        this.level = "コリアンタイピングマスター！！！";
      }
    },
    // キーボードを光らせる
    koreanChange() {
      if (this.keySuport == true) {
        let originText = this.questions[this.randomMath[this.textIndex]].origin; // オリジナル文章
        let firstText = originText.charAt(0); // オリジナル文章の最初の1文字目(1文字目は正解するごとに削除されていく)
        let firstHangul = Hangul.disassemble(firstText); // 最初の１文字目を韓国語で分けたものの配列
        let shiftHangul = ["ㅃ", "ㅉ", "ㄸ", "ㄲ", "ㅆ", "ㅒ", "ㅖ"];
        // shiftが必要かどうかを判定
        for (let i = 0; i < shiftHangul.length; i++) {
          if (firstHangul.indexOf(shiftHangul[i]) !== -1) {
            let num = firstHangul.indexOf(shiftHangul[i]);
            firstHangul.splice(num, 0, "{shiftleft}");
          }
        }
        this.hangulArray = firstHangul;
        if (firstHangul[this.typingNum] == " ") {
          this.hangulChange = "{space}";
        } else if (firstHangul[this.typingNum] == "{shiftleft}") {
          this.hangulChange = "{shiftleft}";
        } else {
          this.hangulChange = firstHangul[this.typingNum];
        }
      }
    },
    // inputを空にする
    inputClear() {
      // スマホまたはwindowsかつ、2問目以降かつ、1文字目の場合
      if (
        (this.DisplayWidth < 640 || this.os === "windows") &&
        this.textIndex > 0 &&
        this.NowText === 0
      ) {
        // 一つ前の問題を格納
        let oldIndex = this.textIndex - 1;
        let oldText = this.questions[this.randomMath[oldIndex]].origin2;
        if (oldText.charAt(oldText.length - 1) === this.input.charAt(0)) {
          // this.input = this.input.substring(1);
          this.input = this.input.slice(1);
        }
      }
    },
    // 苦手キーの表示
    // 参考: https://qiita.com/saka212/items/408bb17dddefc09004c8
    missKey() {
      // ミス集をまとめて配列に入れる
      let count = [];
      for (var i = 0; i < this.missTyping.length; i++) {
        var elm = this.missTyping[i];
        count[elm] = (count[elm] || 0) + 1;
      }
      // https://qiita.com/yunkikki/items/6278c9215e6f46c4e55f
      // ミス集を順番に並び変える
      let arr = Object.keys(count).map((e) => ({ key: e, value: count[e] }));
      arr.sort(function (a, b) {
        if (a.value < b.value) return 1;
        if (a.value > b.value) return -1;
        return 0;
      });
      // 5以降は削除
      if (arr.length > 5) {
        this.missTyping = arr.slice(0, 5);
      } else {
        this.missTyping = arr;
      }
      for (let i = 0; i < this.missTyping.length; i++) {
        this.missTyping2.push(this.missTyping[i].key);
      }
    },
    // 問題の全体の文字数
    questionTypeCount() {
      let typeCount = 0;
      for (let i = 0; i < this.questions.length; i++) {
        typeCount += this.questions[i].origin.length;
      }
      this.originLength = typeCount;
    },
    // デバイス情報取得
    getDviceType() {
      var ut = navigator.userAgent;
      if (
        ut.indexOf("iPhone") > 0 ||
        ut.indexOf("iPod") > 0 ||
        (ut.indexOf("Android") > 0 && ut.indexOf("Mobile") > 0)
      ) {
        this.device_type = "sp";
      } else if (ut.indexOf("iPad") > 0 || ut.indexOf("Android") > 0) {
        this.device_type = "sp";
      } else {
        this.device_type = "pc";
      }
    },
    // タイピングID取得
    getTypingID() {
      this.axios
        .get(this.apiUrl + "rank/get_typing_id", {
          headers: {
            Authorization: "Bearer " + this.token,
            "Content-type": "application/json",
            Accept: "application/json,",
            data: {},
          },
        })
        .then((res) => {
          if (res.data.status_code === 200) {
            this.typingID = res.data.typing_id;
            this.getQuestion(); //問題取得
          } else {
            alert("問題が発生しました");
            this.$router.push({ path: "/" });
          }
        })
        .catch((e) => {
          if (e.response.data.message === "Unauthenticated.") {
            alert("問題が発生しました。再度ログインをお願いします");
            localStorage.clear();
            this.$router.push({ path: "/login" });
          }
        });
      this.getTypingIdFlug = true;
    },
    // 問題取得
    getQuestion() {
      if (this.getTypingIdFlug) {
        this.axios
          .get(this.apiUrl + "question/show/" + this.typingID)
          .then((res) => {
            this.title = res.data.title;
            this.seoTitle = res.data.seo_title;
            this.seoDescription = res.data.seo_description;
            let question_json = JSON.parse(res.data.question);
            for (let i = 0; i < question_json.length; i++) {
              // 問題
              this.questions.push({
                origin: question_json[i].korean,
                origin2: question_json[i].korean, //文字を消しているため過去問の保存
                translation: question_json[i].japanese,
              });
            }
            // サイト紹介
            this.siteIntroduction = JSON.parse(res.data.site_introduction);
            this.randomNum(); // ランダムな配列生成
            this.questionTypeCount(); // 全体の文字数取得
          });
      }
    },
    // ユーザID取得
    getUserID() {
      if (localStorage.getItem("loginData") === null) {
        alert("ログインしてください");
        this.$router.push({ path: "/login" });
      } else {
        const obj = localStorage.getItem("loginData");
        const loginData = JSON.parse(obj);
        this.userID = loginData.userID;
        this.token = loginData.token;
      }
    },
    // スコア登録OK判定
    scoreRegister() {
      this.register = true;
      this.storeRankingScore();
    },
    // スコア登録
    storeRankingScore() {
      this.axios
        .post(this.apiUrl + "rank/store", {
          user_id: Number(this.userID),
          typing_id: Number(this.typingID),
          score: this.score,
          device_type: this.device_type,
        })
        .then((res) => {
          if (res.data.status_code === 200) {
            alert("スコアをランキングに登録しました!");
          }
          if (res.data.status_code === 201) {
            alert("ランキングのスコアを更新しました!");
          }
          if (res.data.status_code === 202) {
            alert(
              "前回のスコアの方が良い結果だったため、スコアは更新しませんでした"
            );
          }
          this.rankFlug = true;
          this.$router.push({ path: "/ranking" });
        });
    },
    // OS判定
    getOS() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf("windows nt") !== -1) {
        this.os = "windows";
      } else if (ua.indexOf("mac os x") !== -1) {
        this.os = "macOS";
        // this.os = "windows";
      }
    },
  },
  // // ページ離脱時に発火
  // unmounted() {
  //   if (this.completed && this.rankFlug === false) {
  //     this.storeRankingScore();
  //   }
  // },
});
</script>

<style lang="scss" scoped>
.font-size-big {
  font-size: 35px;
}

.bg-color {
  background-color: white;
}

.bg-color-input {
  background-color: skyblue !important;
  height: 40px;
  width: 80%;
}

.text-blue {
  color: blue;
  font-weight: 600;
}

.text-blue-data {
  color: #027fff;
  font-weight: 600;
}

.miss-text {
  color: rgb(255, 85, 0);
  font-size: large;
  font-weight: bold;
}
.deco-none {
  text-decoration: none;
}
</style>
