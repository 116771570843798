<template>
  <v-app class="bg-img">
    <div class="py-3 bg-img"></div>
    <v-container>
      <v-row>
        <v-col md="8" cols="12">
          <v-card class="text-center" color="#B3E5FC" :height="cardHeight">
            <div class="py-2"></div>
            <v-row>
              <v-col>
                <v-card-title
                  v-if="this.url == 'check'"
                  class="grey-color font-bold"
                  >腕試しレベルチェック</v-card-title
                >
                <v-card-title v-if="this.url == 'work'">仕事</v-card-title>
                <v-card-title v-if="this.url == 'life'">生活</v-card-title>
                <v-card-title v-if="this.url == 'love'">恋愛</v-card-title>
                <v-card-title v-if="this.url == 'TrilingualTomi'"
                  >トリリンガルのトミ韓国講座</v-card-title
                >
                <!-- <v-card-title v-if="this.url == 'drama'">ドラマ</v-card-title> -->
                <v-card-title v-if="this.url == 'kpop'">K-POP</v-card-title>
                <v-card-text v-if="this.url == 'check'"
                  >ここではあなたの現在の<br />タイピングレベルを<br />判定するテストを行います。
                </v-card-text>
                <v-card-text v-if="this.url == 'work'"
                  >ここでは仕事に関する単語やフレーズで<br />タイピングレベルを測ります<br /><br />気になるタイトルをクリックしてスタート！
                </v-card-text>
                <v-card-text v-if="this.url == 'life'"
                  >ここでは生活に関する単語やフレーズで<br />タイピングレベルを測ります<br /><br />気になるタイトルをクリックしてスタート！
                </v-card-text>
                <v-card-text v-if="this.url == 'love'"
                  >ここでは恋愛に関する単語やフレーズで<br />あなたのタイピングレベルを測ります。<br /><br />気になるタイトルをクリックしてスタート！
                </v-card-text>
                <v-card-text v-if="this.url == 'TrilingualTomi'">
                  ここでは、「<a
                    href="https://trilingual.jp/"
                    class="deco-none-blue"
                    target="_brank"
                    rel="noopener noreferrer"
                    >トリリンガルのトミ韓国講座</a
                  >」で解説されているフレーズや単語であなたのタイピングレベルを測ります。<br /><br />
                  まずは、レベルを選択しましょう！
                </v-card-text>
                <v-card-text v-if="this.url == 'kpop'"
                  >ここではK-POPの歌詞で<br />あなたのタイピングレベルを測ります<br /><br />気になるアーティストを選択しましょう！
                </v-card-text>
                <div class="py-4"></div>
                <v-btn
                  color="#ffff99"
                  :to="'/game-start/check/' + checkQuestion"
                  v-if="this.url == 'check'"
                  height="70"
                >
                  今すぐチェック
                </v-btn>
                <!-- <div class="py-4"></div> -->
                <v-row>
                  <!-- <v-col>
                  <router-link to="/">現在のランキングを見る</router-link>
                </v-col>
                <v-col>
                  <router-link to="/">過去のランキング</router-link>
                </v-col> -->
                  <!-- <v-col>
                    <router-link to="/">上手く使えないときは？</router-link>
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col v-if="this.width > 500">
                <v-img
                  :src="require('@/assets/zoo.png')"
                  :width="imgWidth"
                ></v-img>
              </v-col>
              <!-- <v-btn @click="rule"> 遊び方 </v-btn>
            <br />
            <br />
            <v-btn @click="setting"> 設定 </v-btn> -->
            </v-row>
          </v-card>
          <div class="py-3"></div>
          <v-card no-gutters v-if="this.url == 'check'">
            <v-card-title class="card-title">使い方</v-card-title>
            <v-card-text>
              <p>
                ①上の「今すぐチェック」をクリックするとタイピング画面が表示されます。
              </p>
              <br />
              <br />
              ②次に画面内の「スタート」ボタンをクリックします<br />
              例文の下に表示される文字を入力してください。
              <br />
              <br />
              ③すべての例文を入力し終わると終了し、練習結果を表示します。<br />
            </v-card-text>
          </v-card>
          <br />
          <v-card no-gutters v-if="this.url == 'check' && this.width > 500">
            <v-card-title class="card-title"
              >レベルはどうなっているの？</v-card-title
            >
            <v-card-text>
              今の自分のレベルを意識して、ひとつ上のレベルを常に目指して練習してください。
              レベルの設定は以下のようになっています。
              「Good!」より上もあるのですが、そこからは練習をつんでご自身でご確認ください
            </v-card-text>
            <v-card-text class="text-center" v-if="this.width > 500">
              <v-simple-table height="600px" width="100%">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">レベル</th>
                      <th class="text-left">スコア</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in desserts" :key="item" class="text-left">
                      <td class="about_level">{{ item.name }}</td>
                      <td class="about_level">{{ item.calories }}</td>
                      <td
                        v-if="item.sel !== ''"
                        :rowspan="item.sel"
                        v-html="item.discription"
                        class="about_level_discription"
                      ></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <!-- ローディング -->
          <div v-if="this.loding == true">
            <loding />
          </div>
          <div v-else>
            <!-- トミさんの場合 -->
            <v-row v-if="url == 'TrilingualTomi'">
              <v-col
                md="12"
                cols="12"
                v-for="(level, i) in tomisLevel"
                :key="i"
              >
                <v-card
                  class="text-left card-margin"
                  :to="'TrilingualTomi/' + level.url"
                  width="100%"
                >
                  <v-card-title
                    class="card-title"
                    v-text="level.title"
                  ></v-card-title>
                  <v-card-text v-html="level.discription"></v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- とみさん以外の場合 -->
            <v-row v-if="url !== 'TrilingualTomi' && url !== 'check'">
              <v-col md="6" cols="12" v-for="(content, i) in contents" :key="i">
                <v-card
                  class="text-left card-margin"
                  :to="'/game-start/' + url + '/' + content.id"
                >
                  <v-card-title
                    class="card-title"
                    v-text="content.title"
                  ></v-card-title>
                  <v-card-text v-html="content.description"></v-card-text>
                  <v-card-text class="font-count text-right">
                    表示回数 {{ content.count }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col md="4" cols="12">
          <SelectVariety />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import SelectVariety from "../SelectVariety.vue";
import loding from "../myLoding.vue";

export default defineComponent({
  components: {
    SelectVariety,
    loding,
  },
  data() {
    return {
      dialog: false, // ダイアログのtrue,false情報
      loding: true,
      levelSelect: "初級",
      easy: false,
      middle: false,
      hard: false,
      url: "",
      error: "",
      title: "",
      getFlug: false,
      desserts: [
        {
          name: "？？？",
          calories: "299~",
          discription:
            "一般的なオフィスワークでは困ることのないレベルです。<br />プロレベルにはまだ届きません。<br />より高いレベルを目指すには、正確さを高めましょう",
          sel: 3,
        },
        {
          name: "Good!",
          calories: "276~299",
          sel: "",
        },
        {
          name: "S",
          calories: "259~276",
          sel: "",
        },
        {
          name: "A",
          calories: "208~258",
          discription:
            "個人的な用途でのパソコン利用には問題のないレベルです。<br />正確さを意識して更にスコアアップを目指しましょう。",
          sel: 3,
        },
        {
          name: "B",
          calories: "157~207",
          sel: "",
        },
        {
          name: "C",
          calories: "106~156",
          sel: "",
        },
        {
          name: "D",
          calories: "55~105",
          discription:
            "タイピングが生まれつきできる人はいません。<br />誰もが努力してできるようになっていきます。<br />ここからスタートです。",
          sel: 2,
        },
        {
          name: "E",
          calories: "~54",
          sel: "",
        },
      ],
      // 各種サイズ
      width: 0,
      cardHeight: 0,
      imgWidth: 0,
      // コンテントフル
      texts: [],
      contents: [],
      artists: [],
      tomisLevel: [
        {
          title: "初級",
          url: "beginner",
          discription:
            "韓国語初学者レベルのタイピング！<br>基礎的な単語や文法でタイピングの練習ができます！",
        },
        {
          title: "中級",
          url: "intermediate",
          discription:
            "韓国語中級者レベルのタイピング！<br>韓国語能力試験4級、ハングル検定だと3級~準2級くらいのレベルです！",
        },
        // {
        //   title: "上級",
        //   url: "advanced",
        //   discription: "",
        // },
      ],
      checkQuestion: null,
      apiUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  async created() {
    // 情報取得
    const url = this.$route.path.split("/");
    this.url = url[2];
    // 画面幅取得
    this.width = window.innerWidth;
    // サイズ調整
    if (this.url !== "check") {
      this.cardHeight = 200;
      this.imgWidth = 200;
    } else {
      this.cardHeight = 300;
      this.imgWidth = 300;
    }
    // ヘッダー
    this.getTitle();
    // laravelAPI
    this.getQuestion();
    this.getCheckQuestion();
  },
  methods: {
    // タイトル取得
    getTitle() {
      if (this.url == "check") {
        this.title = "腕試しレベルチェック";
      }
      if (this.url == "work") {
        this.title = "仕事";
      }
      if (this.url == "life") {
        this.title = "生活";
      }
      if (this.url == "love") {
        this.title = "恋愛";
      }
      if (this.url == "TrilingualTomi") {
        this.title = "トリリンガルのトミ韓国講座";
      }
      this.getFlug = true;
      this.setup();
    },
    // ヘッダー情報
    setup() {
      const siteData = reactive({
        title:
          this.title +
          "に関する問題選択|コリアンタイピング(韓国語のタイピング練習アプリ)",
        description:
          this.title +
          "に関するお好みの問題を選択して韓国語のタイピングを練習しましょう！",
      });
      useHead({
        // Can be static or computed
        title: computed(() => siteData.title),
        meta: [
          {
            name: "description",
            content: computed(() => siteData.description),
          },
        ],
      });
    },
    getQuestion() {
      this.axios.get(this.apiUrl + "question/index/" + this.url).then((res) => {
        this.contents = res.data;
        this.loding = false;
      });
    },
    getCheckQuestion() {
      this.axios.get(this.apiUrl + "check").then((res) => {
        this.checkQuestion = res.data.typing_id;
      });
    },
  },
});
</script>
<style lang="scss" scoped>
#app {
  background-color: #f7f7f7;

  .container {
    width: 100%;

    .row {
      width: 100%;

      .card {
        width: 100%;
        height: 100%;

        .profile-img {
          width: 100%;
        }

        .form-signin {
          width: 100%;
          padding: 0px 0 0px 0;
          margin: 0 auto;

          .checkbox {
            margin-bottom: 10px;
          }

          .btn-primary {
            margin: 20px 0 !important;
            width: 80%;
          }
        }
      }
    }
  }
}
.font-count {
  font-size: small;
  margin-top: 0;
  padding-top: 0;
  font-weight: bold;
  color: yellowgreen;
}
.card-back {
  background-color: #e1f5fe;
}
.card-title {
  // light-blue lighten-2
  background-color: #4fc3f7;
  margin-bottom: 20px;
}
.about_level {
  border: 1px solid #d4d4d4;
  padding: 40px;
  background-color: #d9f4fd;
}
.about_level_discription {
  border: 1px solid #d4d4d4;
  padding: 80px;
}
.form-signin .form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}
.form-signin input[type="password"] {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin-bottom: -1px;
}
.login-title {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  display: block;
}
.new-account {
  display: block;
  margin: 10px 0;
}
#error {
  color: red;
  display: none;
}
</style>
