<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <div class="py-3"></div>
      <v-card width="350px" class="mx-auto mt-4" cols="12" sm="4">
        <v-card-title class="card-title">
          <h3 class="display-2">パスワード再設定</h3>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="メールアドレス"
              v-model="email"
            />
            <v-text-field
              v-bind:type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="パスワード"
              @click:append="showPassword = !showPassword"
              v-model="password"
            />
            <v-text-field
              v-bind:type="showPassword2 ? 'text' : 'password2'"
              prepend-icon="mdi-lock"
              v-bind:append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
              label="確認用パスワード"
              @click:append="showPassword2 = !showPassword2"
              v-model="password2"
            />
            <v-card-actions>
              <v-btn color="blue" @click="Resetpassword"
                >パスワードを変更する</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    url: "",
    email: "",
    showPassword: false,
    showPassword2: false,
    password: "",
    password2: "",
    token: "",
    apiUrl: process.env.VUE_APP_API_BASE_URL,
  }),
  created: function () {
    this.getParam();
  },
  methods: {
    getToken: function () {
      if (localStorage.getItem("loginData") === null) {
        return;
      } else {
        const obj = localStorage.getItem("loginData");
        const loginData = JSON.parse(obj);
        this.token = loginData.token;
      }
    },
    getParam: function () {
      const url = this.$route.path.split("/");
      this.token = url[2];
      this.email = url[3];
    },
    Resetpassword: function () {
      if (this.email === "") {
        alert("メールアドレスを入力してください");
        return;
      }
      if (this.password === "") {
        alert("パスワードを入力してください");
        return;
      }
      if (this.password !== this.password2) {
        alert("パスワードが異なっております。再度入力してください");
        this.password = "";
        this.password2 = "";
        return;
      }
      if (this.password.length < 8) {
        alert("パスワードは8文字以上で入力してください");
        this.password = "";
        this.password2 = "";
        return;
      }

      this.axios
        .post(this.apiUrl + "reset-password", {
          token: this.token,
          password: this.password,
          rePassword: this.password2,
          email: this.email,
        })
        .then((res) => {
          if (res.data.message === "passwords.reset") {
            alert("パスワード再設定が成功しました。再度ログインしてください");
            location.href = "/login";
          }
        })
        .catch((error) => {
          if (error.message === "Request failed with status code 422") {
            alert(
              "何らかのエラーが発生しました。お手数をおかけしますが、パスワード再設定用メールの送信からお試しください"
            );
            location.href = "/password-remind";
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
