<template>
  <v-app>
    <v-card class="overflow-hidden card-toolbar" width="100%">
      <v-container>
        <v-toolbar
          Fixed
          tabs
          flat
          class="toolbar"
          :height="50"
          width="997px"
          color="#B3E5FC"
          id="page-top"
          scroll-target="#scrolling-techniques-2"
        >
          <v-row class="center">
            <v-col class="margin-top center" sp="12">
              <v-app-bar-nav-icon
                @click="drawer = true"
                class="hidden-lg-only text-center"
              ></v-app-bar-nav-icon>
              <v-toolbar-title
                @click="$router.push('/')"
                class="grey-color font-bold ma-0"
              >
                コリアンタイピング
              </v-toolbar-title>

              <v-tabs
                v-if="loginFlug"
                class="hidden-md-and-down tab-toolbar"
                justify="right"
              >
                <v-tab router-link to="/game-select/check"
                  >タイピング練習</v-tab
                >
                <v-tab router-link to="/contact">お問い合わせ</v-tab>
                <!-- <v-tab router-link to="/chart">カルテ</v-tab> -->
                <!-- <v-tab router-link to="/ranking">ランキング</v-tab> -->
                <!-- <v-tab router-link to="/mypage">マイページ</v-tab> -->
                <!-- <v-tab router-link to="/logout">ログアウト</v-tab> -->
              </v-tabs>

              <v-tabs v-else class="hidden-md-and-down tab-toolbar">
                <v-tab router-link to="/game-select/check"
                  >タイピング練習</v-tab
                >
                <v-tab router-link to="/contact">お問い合わせ</v-tab>
                <!-- <v-tab router-link to="/login">ログイン</v-tab> -->
                <!-- <v-tab router-link to="/sign-in">会員登録</v-tab> -->
              </v-tabs>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-container>

      <!-- <v-app-bar
        absolute
        color="#B3E5FC"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
        scroll-target="#scrolling-techniques-2"
        :height="100"
        id="page-top"
      >
        <v-container>
          <v-row class="center">
            <v-col class="margin-top center" sp="12">
              <v-app-bar-title
                @click="$router.push('/')"
                class="grey-color font-bold"
                >コリアンタイピング</v-app-bar-title
              >
            </v-col>

            <v-spacer></v-spacer>
            <div v-if="!loginFlug">
              <v-btn to="/login">ログイン</v-btn>
              <v-btn to="/sign-in">会員登録</v-btn>
            </div>
            <div v-else>
              <v-btn to="/mypage">マイページ</v-btn>
              <v-btn to="/chart">カルテ</v-btn>
              <v-btn to="/ranking">ランキング</v-btn>
              <v-btn to="/logout">ログアウト</v-btn>
            </div>
          </v-row>
        </v-container>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-2"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="height: 70px"></v-container>
      </v-sheet>
       -->
      <router-view />
    </v-card>

    <!-- スマホ用 -->
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <!-- ログイン済み -->
      <!-- <v-list nav dense v-if="loginFlug">
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title @click="$router.push('/')"
              >HOME
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/game-select/check')">
              タイピング問題
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/chart')">
              カルテ
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/ranking')">
              ランキング
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/mypage')">
              マイページ
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/logout')">
              ログアウト
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list> -->

      <!-- ログインなし -->
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title @click="$router.push('/')"
              >HOME
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/game-select/check')">
              タイピング問題
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/contact')">
              お問い合わせ
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title @click="$router.push('/login')">
              ログイン
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/sign-in')">
              新規会員登録
            </v-list-item-title>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- 戻るボタン -->
    <div id="goBack">
      <v-btn icon @click="$router.go(-1)" color="#3f98ef">
        <v-icon color="white">mdi-chevron-left</v-icon>
      </v-btn>
    </div>

    <v-footer class="footer-color" padless>
      <v-row no-gutters justify="center">
        <!-- <v-col  md="3" cols="12"> -->
        <router-link
          v-for="(link, i) in links"
          :key="i"
          color="white"
          class="deco-none"
          :to="link.url"
        >
          {{ link.name }}
          <span v-if="i !== this.links.length - 1"> ㅣ</span></router-link
        >
        <!-- </v-col> -->
        <!-- <v-col class="primary lighten-2 py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} — <strong>Vuetify</strong>
        </v-col> -->
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: false,
    loginFlug: false,
    links: [
      {
        name: "home",
        url: "/",
      },
      {
        name: "よくあるご質問",
        url: "/QandA",
      },
      {
        name: "お問い合わせ",
        url: "/contact",
      },
      {
        name: "プライバシーポリシー",
        url: "/privacy",
      },
      {
        name: "利用規約",
        url: "/term",
      },
      {
        name: "サイト運営者",
        url: "/blog/site-Administrator",
      },
    ],
    url: "",
    width: 0,
    apiUrl: process.env.VUE_APP_API_BASE_URL,
  }),
  created() {
    this.getlocalStorage();
    const url = this.$route.path.split("/");
    this.url = url[0];
    this.width = window.innerWidth;
  },
  methods: {
    getlocalStorage() {
      const obj = localStorage.getItem("loginData");
      const loginData = JSON.parse(obj);
      if (loginData !== null) {
        this.loginFlug = true;
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  .card-toolbar {
    background-color: #b2e5fb;
    margin: 0 auto;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .toolbar {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    justify-content: center !important;
  }
  .tab-toolbar {
    margin-left: 150px;
  }
  .bg-img {
    background-image: url("~@/assets/bg.png");
    width: 100%;
  }
  .footer-color {
    background-color: #b3e5fc;
    height: 80px;
  }
  /* 戻るボタン */
  #goBack {
    width: 60px;
    height: 60px;
    position: fixed;
    left: 0;
    bottom: 0;
    opacity: 0.6;
    margin: 10px;
  }
  .deco-none {
    text-decoration: none;
    color: #4d4d4d;
    margin-bottom: 20px;
  }
}
li {
  margin-left: 15px;
}
.deco-none-blue {
  text-decoration: none;
  color: #0080ff;
  font-weight: bold;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
