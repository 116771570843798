<template>
  <v-app>
    <v-container>
      <p class="mb-4">あなたは現在、〇〇会員です</p>
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="mx-auto">
            <v-img
              src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
              height="200px"
              cover
            ></v-img>
            <v-card-title> Freeプラン </v-card-title>
            <v-card-subtitle>
              <ul>
                <li>無料で韓国語のタイピング練習ができる！</li>
                <li>ランキングで全国のユーザと競うことができる！</li>
              </ul>
            </v-card-subtitle>
            <v-col class="text-center">
              <v-btn
                class="ma-4"
                width="80%"
                color="orange-lighten-2 text-gray"
                href="/sign-in"
              >
                会員登録はこちら
              </v-btn>
            </v-col>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="mx-auto">
            <v-img
              src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
              height="200px"
              cover
            ></v-img>

            <v-card-title> Proプラン (¥300/月)</v-card-title>

            <v-card-subtitle>
              <ul>
                <li>K-POPで韓国語のタイピング練習ができる！</li>
                <li>タイピング記録を残し成長グラフが確認できる！</li>
              </ul>
            </v-card-subtitle>

            <v-col class="text-center">
              <v-btn
                class="ma-4"
                width="80%"
                color="orange-lighten-2 text-gray"
                :href="this.stripeUrl"
              >
                Proプランに変更する
              </v-btn>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <!-- 本番 -->
      <!-- <a href="https://billing.stripe.com/p/login/bIY9BbaU6cy81G0144">
        キャンセル</a
      > -->

      <!-- テスト環境 -->
      <a :href="cancelUrl"> キャンセル</a>
      <!-- <a :href="cancelUrl"> キャンセル処理 </a> -->
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    show1: false,
    show2: false,
    publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    apiUrl: process.env.VUE_APP_API_BASE_URL,
    stripeApiUrl: "https://api.stripe.com",
    loading: false,
    headers: {
      Authorization: "",
      "Content-type": "application/json",
      Accept: "application/json,",
      data: {},
    },
    stripe: null,
    email: "",
    userID: "",
    token: "",
    stripeUrl: "",
    stripePromise: null,
    status: false,
    stripeId: null,
    cancelUrl: "",
  }),
  created() {
    // this.stripe = loadStripe(this.publishableKey);
    this.getUserID();
    this.getStatus(); // 現在の課金状況取得
  },
  //   mounted() {
  //     this.getCancelurl();
  //   },
  computed: {},
  methods: {
    // ユーザID取得
    getUserID() {
      if (localStorage.getItem("loginData") === null) {
        alert("ログインしてください");
        this.$router.push({ path: "/login" });
      } else {
        const obj = localStorage.getItem("loginData");
        const loginData = JSON.parse(obj);
        this.userID = loginData.userID;
        this.token = loginData.token;
        this.headers.Authorization = "Bearer " + this.token;
        this.getUser(); // ユーザ情報取得
      }
    },
    // ユーザ情報取得
    getUser() {
      this.axios
        .get(this.apiUrl + "user", {
          headers: this.headers,
        })
        .then((res) => {
          this.email = res.data.email;
          this.userID = res.data.id;
          this.createCancelurl();
        });
    },
    // 課金状況取得
    async getStatus() {
      const url = this.apiUrl + "subscription/getStatus";
      let res = await this.axios.get(url, { headers: this.headers });
      if (res.data) {
        this.status = res.data.stripe_status;
        this.stripeId = res.data.stripe_id;
      }

      // this.getCancelurl(); // キャンセル用のURL取得
      this.createStripeURL(); // 申し込み用url取得

      //   if (this.status === "paid") {
      //     // this.getCancelurl(); // キャンセル用のURL取得
      //   }

      //   if (this.status === "canceled") {
      //     this.createStripeURL(); // 申し込み用url取得
      //   }
    },
    // stripeリンクのパラメータ作成
    createStripeURL() {
      const url =
        "https://buy.stripe.com/test_28odRpdtGbFH9d6288?prefilled_email=";
      this.stripeUrl = url + this.email + "&client_reference_id=" + this.userID;
    },
    // キャンセルのためのカスタマーポータルURL取得
    async createCancelurl() {
      const url = this.apiUrl + "subscription/billing-portal-url";
      await this.axios.get(url, { headers: this.headers });

      //   const url = "https://billing.stripe.com/p/login/test_4gw3dMdzCa9ran6288";
      //   const email = "?prefilled_email=" + this.email;
      //   const user_id = "&client_reference_id=" + this.userID;
      //   this.cancelUrl = url + email + user_id;
      //   this.cancelUrl = encodeURI(this.apiUrl + "billing-portal");
      //   this.cancelUrl = backUrl + "billing-portal";
    },
  },
});
</script>
