<template>
  <!-- <v-app> -->
  <router-view />
  <div :class="keyboardClass"></div>
  <!-- </v-app> -->
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
// import layout from "simple-keyboard-layouts/build/layouts/korean";
// import hangulChange from "@/plugins/hangulChange";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
    input: {
      type: String,
    },
    hangul: {
      type: String,
    },
    typing: {
      type: String,
    },
    keydown: {
      type: String,
    },
    keyup: {
      type: String,
    },
    shiftToggle: {
      type: Boolean,
    },
  },
  emits: ["onChange", "onKeyPress"],
  data: () => ({
    keyboard: null,
    typingText: "",
    text: "",
    KeyDown: "",
    KeyUp: "",
    sDownCount: 0,
    sUpCount: 0,
    OS: "",
  }),
  mounted() {
    this.typingText = this.hangul;
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      physicalKeyboardHighlight: true,
      syncInstanceInputs: true,
      mergeDisplay: true,
      buttonTheme: [
        {
          class: "hg-blue",
          buttons: this.typingText,
        },
      ],
      layout: {
        default: [
          "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
          "{tab} ㅂ ㅈ ㄷ ㄱ ㅅ ㅛ ㅕ ㅑ ㅐ ㅔ @ [ \\",
          "{lock} ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ ; : {enter}",
          "{shiftleft} ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ ㅡ , . / _ {shiftright}",
          "{metaleft} {space} {metaright}",
        ],
        shift: [
          "` ! # $ % & ' ( ) 0 = ~ | {bksp}",
          "{tab} ㅃ ㅉ ㄸ ㄲ ㅆ ㅛ ㅕ ㅑ ㅒ ㅖ @ [ \\",
          "{lock} ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ ; : {enter}",
          "{shiftleft} ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ ㅡ , . / _ {shiftright}",
          "{metaleft} {space} {metaright}",
        ],
      },
      display: {
        "{escape}": "esc ⎋",
        "{tab}": "tab ⇥",
        "{backspace}": "backspace ⌫",
        "{enter}": "enter ↵",
        "{capslock}": "caps lock ⇪",
        "{shiftleft}": "shift ⇧",
        "{shiftright}": "shift ⇧",
        "{controlleft}": "ctrl ⌃",
        "{controlright}": "ctrl ⌃",
        "{metaleft}": "cmd ⌘",
        "{metaright}": "cmd ⌘",
      },
      // ...layout,
    });
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);
      if (
        button == "{shiftleft}" ||
        button == "{shiftlright}" ||
        button == "{lock}"
      )
        this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";
      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    changeColor(text) {
      let buttonElement = this.keyboard.getButtonElement(text);
      buttonElement.classList.add("hg-blue");
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
    hangul(newHangul, oldHangul) {
      this.keyboard.removeButtonTheme(oldHangul, "hg-blue");
      this.keyboard.addButtonTheme(newHangul, "hg-blue");
    },
    // 入力された文字
    // typing(newTyping) {
    //   console.log(newTyping);
    // },
    // shiftでの切り替え
    shiftToggle(shiftToggle) {
      if (shiftToggle == true) {
        this.keyboard.setOptions({
          layoutName: "shift",
        });
      } else {
        this.keyboard.setOptions({
          layoutName: "default",
        });
      }
    },
    // 押したボタン
    // keydown(keydown) {
    //   console.log(keydown);
    // },
  },
};
</script>

<style lang="scss">
.simple-keyboard {
  width: 100%;
  // margin-bottom: 60px;
}

.hg-button.hg-blue {
  // background: rgb(255, 0, 0, 0.7);
  background: #2196f3;
  color: white;
}

// .simple-keyboard .hg-theme-default .hg-button .hg-blue {

// }
.hg-highlight {
  box-shadow: 0px 0px 40px 5px rgba(255, 255, 0, 0.5);
  z-index: 1;
}
</style>
