<template>
  <!-- spの場合 -->
  <Line
    :options="chartOptions"
    :data="chartData"
    id="my-chart-id"
    v-if="getFlug && !errorFlug"
  />
  <div class="table" v-if="!errorFlug">
    <v-row class="text-center border" justify="center" align-content="center">
      <v-col
        cols="12"
        md="4"
        xs="12"
        class="bg-color-grey outlined tile"
        align="center"
      >
        <p class="ma-center">
          <span class="label">最高レベル</span><br />
          <span class="bold">{{ spMaxLevel }}</span>
        </p>
      </v-col>
      <v-row class="text-center pa-4">
        <v-col cols="4" class="bg-white">
          <p class="label">最高スコア</p>
          <p class="sub-font">{{ spMaxScore }}</p>
        </v-col>
        <v-col cols="4" class="bg-white">
          <p class="label">総練習回数</p>
          <p class="sub-font">{{ this.spData.length }}</p>
        </v-col>
        <v-col cols="4" class="bg-white">
          <p class="label">入力文字数</p>
          <p class="sub-font">{{ spMaxTypeCount }}</p></v-col
        >
      </v-row>
    </v-row>
    <div class="py-5"></div>
    <p>
      韓国語のタイピングはもう慣れましたか？<br />
      スマホでの韓国語のタイピングが早くなれば韓国人の友達とスムーズにやり取りができる！<br />
      返信が早いと韓国人の恋人ができるかも、、、！
    </p>
  </div>
  <div v-if="errorFlug">
    <p class="mb-15">
      スマホのデータがないようです。<br />
      タイピングを練習するとデータを登録できますので、ぜひご利用くださいませ!
    </p>
    <SelectVariety />
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import checkTypingLevel from "@/plugins/typingLevel"; //タイピングレベル取得
import SelectVariety from "../SelectVariety.vue";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

export default {
  name: "LineChart",
  components: {
    Line,
    SelectVariety,
  },
  data() {
    return {
      chartData: {
        labels: [], // apiで受け取る
        datasets: [
          {
            label: "スコア",
            backgroundColor: "#75D0F3",
            borderColor: "#75D0F3",
            data: [], // apiで受け取る
          },
          {
            label: "WPM",
            backgroundColor: "#80DF25",
            borderColor: "#80DF25",
            data: [], // apiで受け取る
          },
          {
            label: "正確率",
            backgroundColor: "#FFBFFF",
            borderColor: "#FFBFFF",
            data: [], // apiで受け取る
          },
        ],
      },
      chartOptions: {
        responsive: true,
        color: "#4D4D4D",
      },
      userID: null,
      maxLevel: null,
      spMaxLevel: null,
      token: "",
      width: null,
      data: [],
      spData: [],
      maxScore: null, //最高スコア
      spMaxScore: null, //sp最高スコア
      maxTypeCount: null, // 全体のタイピング数
      spMaxTypeCount: null, // sp全体のタイピング数
      missType: [], //最新ミスタイプ
      sp_data: [],
      getFlug: false,
      labels: [],
      errorFlug: false,
      apiUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  created() {
    this.width = window.innerWidth;
    this.getUserInfo();
  },
  methods: {
    // ユーザID取得
    getUserInfo() {
      if (localStorage.getItem("loginData") === null) {
        this.$router.push({ path: "/" });
        return;
      } else {
        const obj = localStorage.getItem("loginData");
        const loginData = JSON.parse(obj);
        this.userID = loginData.userID;
        this.token = loginData.token;
        this.getScoresTable(); //スコア取得
      }
    },
    // scoresテーブルから情報取得
    async getScoresTable() {
      let resdata = [];
      this.data = [];
      await this.axios
        .get(this.apiUrl + "score/get", {
          headers: {
            Authorization: "Bearer " + this.token,
            "Content-type": "application/json",
            Accept: "application/json,",
            data: {},
          },
        })
        .then((res) => {
          if (res.data.status_code === 200) {
            //  SPの場合
            resdata = res.data.sp_data;
            // データがなければ、エラーメッセージ表示
            resdata = [];
            if (resdata.length < 1) {
              this.errorFlug = true;
              return;
            }
            this.spData = res.data.sp_data;
            this.spMaxScore = res.data.sp_max_score; //最高スコア
            this.spMaxLevel = checkTypingLevel(this.spMaxScore); //最高レベル
            this.spMaxTypeCount = res.data.sp_all_typing_count; //総タイピング数
            this.spmMaxMissTypeCount = res.data.sp_all_miss_count; //総ミスタイピング数
            // resdata = res.data.user_info;
            // this.data = res.data.user_info;
            // this.maxScore = res.data.max_score; //最高スコア
            // this.maxLevel = checkTypingLevel(this.maxScore); //最高レベル
            // this.maxTypeCount = res.data.all_typing_count; //総タイピング数
            // this.maxMissTypeCount = res.data.all_miss_count; //総ミスタイピング数
            for (let i = 0; i < resdata.length; i++) {
              // ラベル(日付)
              let created = new Date(resdata[i].created_at);
              this.chartData.labels.push(this.dateChange(created));
              // スコア格納
              this.chartData.datasets[0].data.push(resdata[i].score);
              // WPM格納
              this.chartData.datasets[1].data.push(resdata[i].wpm);
              // 正確率格納
              this.chartData.datasets[2].data.push(resdata[i].accuracy_rate);
              if (i + 1 === resdata.length) {
                this.missType = JSON.parse(resdata[i].miss_key);
              }
            }
            this.getFlug = true;
          } else {
            alert("問題が発生しました");
            // this.$router.push({ path: "/" });
          }
        })
        .catch((e) => {
          if (e.response.data.message === "Unauthenticated.") {
            alert("問題が発生しました。再度ログインをお願いします");
            // localStorage.clear();
            // this.$router.push({ path: "/login" });
          }
        });
    },
    // 日付の変更
    dateChange(date) {
      var dt = date;
      var y = dt.getFullYear();
      var m = ("00" + (dt.getMonth() + 1)).slice(-2);
      var d = ("00" + dt.getDate()).slice(-2);
      var result = y + "/" + m + "/" + d;
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
#my-chart-id {
  background-color: white;
  padding: 3%;
}
.table {
  margin: 20px 0px;
  padding: 10px;
  .label {
    font-size: small;
    margin-bottom: 10px;
    color: #4d4d4d;
  }
  .bold {
    font-size: 25px;
    font-weight: bold;
    color: #00baed;
  }
  .sub-font {
    font-size: 15px;
    font-weight: bold;
    color: #00baed;
  }
}

.bg-color-grey {
  background-color: #f3f3f3;
  border-radius: 8%;
  align-items: center;
  display: flex;
  .ma-center {
    margin: 0 auto;
  }
}
// .bg-white {
//   margin: 2px;
//   padding: 5px;
//   width: 80%;
// }
</style>
